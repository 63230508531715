var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel, FormHelperText, Tooltip, } from '@mui/material';
// translation
import { useLocales } from '../../locales';
export default function RHFSwitch(_a) {
    var name = _a.name, label = _a.label, helperText = _a.helperText, _b = _a.disabled, disabled = _b === void 0 ? false : _b, other = __rest(_a, ["name", "label", "helperText", "disabled"]);
    var control = useFormContext().control;
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, defaultValue: false, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(_Fragment, { children: [_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx(FormControlLabel, __assign({ disabled: disabled, label: "".concat(translate(label), " "), onBlur: field.onBlur, control: _jsx(Switch, __assign({}, field, { readOnly: true, checked: (field === null || field === void 0 ? void 0 : field.value) === undefined ||
                                    (field === null || field === void 0 ? void 0 : field.value) === '' ||
                                    typeof JSON.parse(field.value) !== 'boolean'
                                    ? false
                                    : JSON.parse(field === null || field === void 0 ? void 0 : field.value) })) }, other)) })), !!error && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error && "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) })))] }));
        } }));
}
